<p-messages [(value)]="mensagemFeedback" [enableService]="false" [closable]="false"></p-messages>

<h3 data-cy="titulo-pagina">Alterar Relatório do candidato</h3>

<div *ngIf="(laudoService.resultado$ | async) as resultado">
	<div class="row">
		<div class="col-sm-12">
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="conclusao">Conclusão do Relatório:</label>
							<textarea
								id="conclusao"
								[(ngModel)]="selecionado!.conclusaoLaudo"
								rows="12"
								cols="90"
								data-cy="campo-justificativa"
								[disabled]="desabilitar"
								maxlength="4000"
								(input)="removerNaoPreenchido()"
							></textarea>
							<sgp-message-feedback [show]="erroConclusaoNaoPreenchida" mensagem="Campo obrigatório"></sgp-message-feedback>
						</div>
					</div>
				</div>
			</div>
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<div class="row">
						<div class="col-sm-12 mt-2" *ngIf="selecionado!.comentariosAnaliseEixo!.length > 0">
							<p-accordion [activeIndex]="0">
								<p-accordionTab [header]="tab.eixoAvaliacao!.nome" *ngFor="let tab of selecionado!.comentariosAnaliseEixo">
									<textarea
										id="eixo_{{tab.id}}"
										[(ngModel)]="tab.texto"
										rows="12"
										cols="90"
										data-cy="campo-eixo-{{tab.id}}"
										maxlength="4000"
										(input)="removerNaoPreenchido()"
									></textarea>
									<sgp-message-feedback [show]="tab.naoPreenchido" mensagem="Campo obrigatório"></sgp-message-feedback>
								</p-accordionTab>
							</p-accordion>
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="row mt-4">
					<div class="col text-left mt-2">
						<button (click)="voltar()" *ngIf="!emitido">Voltar</button>
						<button (click)="voltar()" *ngIf="emitido">Fechar</button>
					</div>
					<div class="col text-right">
						<p-button label="Alterar" (click)="alterar()" styleClass="br-button secondary" *ngIf="!emitido"></p-button>
						<p-button label="Emitir" (click)="emitir()" styleClass="br-button primary" *ngIf="!emitido"></p-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-confirmDialog acceptLabel="Confirmar" rejectLabel="Cancelar"></p-confirmDialog>
