import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';

import { BotaoAcaoProfissiograficoComponent } from '@app/shared/botao-acao-profissiografico/botao-acao-profissiografico.component';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { DetalharCargoComponent } from './detalhar-cargo/detalhar-cargo.component';
import { DetalharLaudoCandidatoComponent } from './detalhar-laudo-candidato/detalhar-laudo-candidato.component';
import { DetalharLaudoComponent } from './detalhar-laudo/detalhar-laudo.component';
import { DetalharPerfilCandidatoComponent } from './detalhar-perfil-candidato/detalhar-perfil-candidato.component';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { GraficoEscalaComponent } from '@app/shared/grafico-escala/grafico-escala.component';
import { ImprimirDashPipe } from '@app/core/pipes/imprimirDash.pipe';
import { MessagesModule } from 'primeng/messages';
import { SharedModule } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
	declarations: [DetalharLaudoComponent, DetalharPerfilCandidatoComponent, DetalharCargoComponent, DetalharLaudoCandidatoComponent],
	imports: [
		CommonModule,
		TabViewModule,
		MessagesModule,
		BotaoAcaoProfissiograficoComponent,
		GraficoEscalaComponent,
		SharedModule,
		CoreModule,
		DsGovDirectivesModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class DetalharLaudoModule {}
