<div *ngIf="(detalharLaudoService.detalharLaudo$ | async) as laudo">
	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Relatório do candidato </span>
			</div>
		</div>
		<div class="card-content">
			<div class="row">
				<div class="col">
					<div>
						<span class="text-semi-bold">Pesquisador responsável:</span> {{laudo.responsavelLaudo | imprimirDash | titlecase }}
					</div>
					<div class="mt-2">
						<span class="text-semi-bold">Situação da priorização:</span>
						<span *ngIf="laudo.dataSolicitacaoPrioridade; else naoPriorizado">Priorizado</span>
						<ng-template #naoPriorizado>Não priorizado</ng-template>
					</div>
					<div class="mt-2"><span class="text-semi-bold">Situação do relatório:</span> {{laudo.situacao?.descricao}}</div>
					<div class="mt-2">
						<span class="text-semi-bold">Ciência por parte do gestor do órgão:</span>
						<span *ngIf="laudo.dataCienciaLaudo; else semCiencia">{{laudo.dataCienciaLaudo | date: 'dd/MM/YYYY'}}</span>
						<ng-template #semCiencia>
							<p class="text-base">-</p>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Resumo do perfil do candidato </span>
			</div>
		</div>
		<div class="card-content">
			<div class="text-semi-bold mt-2">Resumo do currículo:</div>
			<div class="mt-2">
				<span *ngIf="laudo.resumoCurriculo; else semResumo">{{laudo.resumoCurriculo}}</span>
				<ng-template #semResumo>
					<p class="text-base">Não foi possível gerar resumo para o currículo.</p>
				</ng-template>
			</div>
		</div>
	</div>

	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Resumo da avaliação do candidato </span>
			</div>
		</div>
		<div class="card-content">
			<img
				[src]="detalharLaudoService.radar"
				alt="Imagem gerada gráfico de radar contendo as características do candidato"
				*ngIf="detalharLaudoService.radar; else semRadar"
			/>
			<ng-template #semRadar>
				<p class="text-base">Não foi possível gerar o gráfico de radar.</p>
			</ng-template>
		</div>
	</div>

	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Eixos de Avaliação</span>
			</div>
		</div>
		<div class="card-content" *ngFor="let comentarioAnaliseEixo of laudo.comentariosAnaliseEixo">
			<div class="label">{{comentarioAnaliseEixo.eixoAvaliacao?.nome }}</div>
			{{ comentarioAnaliseEixo.texto }}
		</div>
	</div>

	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Conclusão do Relatório</span>
			</div>
		</div>
		<div class="card-content">{{ laudo.conclusaoLaudo }}</div>
	</div>
</div>
