<div *ngIf="(questionarioCargo | async) as questionario">
	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Questionário profissiográfico do cargo </span>
			</div>
		</div>
		<div class="card-content">
			<div class="row">
				<div class="col">
					<div class="br-list mt-5" role="list" *ngFor="let grupoPerguntas of questionario.grupoPerguntas">
						<div class="title text-base text-semi-bold px-3 py-2">
							<div class="row">
								<div class="col-md-6">{{grupoPerguntas.nomeGrupo}}</div>
							</div>
							<div class="row">
								<div class="col-md-6">Escala: 
									{{grupoPerguntas.nomeEscalaInicial}} ({{ grupoPerguntas.numeroEscalaInicial}}) - {{
									grupoPerguntas.nomeEscalaFinal}} ({{ grupoPerguntas.numeroEscalaFinal}})
								</div>
							</div>
						</div>
						<span class="br-divider"></span>
						<div class="br-list" role="list" *ngFor="let nivelAvaliacao of grupoPerguntas.nivelAvaliacao">
							<div class="title text-base text-semi-bold px-3 py-2">
								<div class="row">
									<div class="col-md-6 pt-2x">{{nivelAvaliacao.nome}}</div>
								</div>
							</div>
							<div class="br-item" role="listitem" *ngFor="let pergunta of nivelAvaliacao.perguntas">
								<div class="row">
									<div class="col-10">{{ pergunta.textoPergunta }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
