<!-- <div class="bg-yellow-10 my-5 p-2">
	<div class="row">
		<div class="col-sm-6">
			<p><strong>Debug Form</strong></p>
			<pre>{{ form.value | json }}</pre>

			<pre class="bg-red-5">{{ form.status }}</pre>
		</div>
		<div class="col-sm-6">
			<p><strong>Debug Filtro</strong></p>
			<pre>{{ pesquisarService.filtro| json }}</pre>
		</div>
	</div>
</div> -->

<div class="row">
	<div class="col-sm-12">
		<form [formGroup]="form">
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<div class="row">
						<div class="col-sm-12 br-input" [ngClass]="{'danger': form.controls['idConcurso'].errors?.required && submetido}">
							<label for="campo-concurso">Concurso (Obrigatório):</label>
							<p-dropdown
								[options]="(pesquisarService.concursos$ | async)!"
								formControlName="idConcurso"
								(onChange)="selecionarConcurso($event)"
								placeholder="Selecione"
								[showClear]="true"
								optionLabel="nome"
								optionValue="id"
								id="campo-concurso"
								data-cy="campo-concurso"
							></p-dropdown>
							<sgp-message-feedback
								[show]="form.controls['idConcurso'].errors?.required && submetido"
								mensagem="Campo obrigatório"
								data-cy="erro-concurso-obrigatorio"
							></sgp-message-feedback>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-orgao">Órgão:</label>
							<div *possuiPerfil="[perfisEnum.PESQUISADOR, perfisEnum.GESTOR_PRODUTO]">
								<p-dropdown
									[options]="(pesquisarService.orgaos$ | async)!"
									formControlName="codigoOrgao"
									(onChange)="selecionarOrgao($event.value)"
									placeholder="Selecione um concurso para habilitar o campo"
									[showClear]="true"
									optionLabel="nomeOrgao"
									optionValue="codigoOrgao"
									id="campo-orgao"
									data-cy="campo-orgao"
								></p-dropdown>
							</div>
							<div class="mt-2" *ngIf="somenteGestorPessoas">
								{{ (this.orgaoUsuario$ | async)?.nomeReduzidoSiape }}
							</div>
						</div>
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-cargo">Cargo:</label>
							<p-dropdown
								[options]="(pesquisarService.cargos$ | async)!"
								formControlName="codigoCargo"
								[placeholder]="placeholderCargo"
								[showClear]="true"
								optionLabel="nomeCargo"
								optionValue="codigoCargo"
								id="campo-cargo"
								data-cy="campo-cargo"
							></p-dropdown>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-situacao">Situação:</label>
							<p-dropdown
								[options]="(pesquisarService.situacoes$ | async)!"
								formControlName="situacao"
								placeholder="Selecione"
								[showClear]="true"
								optionLabel="descricao"
								optionValue="codigo"
								id="campo-situacao"
								data-cy="campo-situacao"
							></p-dropdown>
						</div>
						<div class="col-sm-6 mt-2 br-input">
							<label for="campo-periodo-envio">Período de Liberação:</label>
							<p-calendar
								[showIcon]="true"
								formControlName="periodo"
								selectionMode="range"
								[readonlyInput]="false"
								inputId="campo-periodo-liberacao"
								dateFormat="dd/mm/yy"
								rangeSeparator=" até "
								placeholder="Período em que o questionário estará liberado para respostas do candidato."
								data-cy="campo-periodo-liberacao"
								[style]="{'minWidth':'350px'}"
							>
							</p-calendar>
							<sgp-message-feedback
								[show]="form.controls['periodo'].errors?.['dataFinalDePeriodoFaltando'] && submetido"
								mensagem="Preencha as duas datas do período"
								data-cy="erro-concurso-obrigatorio"
							></sgp-message-feedback>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="row mt-4">
						<div class="col text-right">
							<button pButton data-cy="btnClear" class="secondary mr-2" (click)="limpar()">Limpar</button>
							<button pButton type="submit" data-cy="btnSubmit" class="primary" (click)="pesquisar()">Pesquisar</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
