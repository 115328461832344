<!-- <pre>
	{{ detalharLaudoService.detalharRespostaCandidato$ | async | json }}
</pre> -->

<p-messages [enableService]="true" [closable]="false" styleClass="mt-4"></p-messages>

<div *ngIf="(questionarioCargo$ | async) as questionario">
	<div class="row">
		<div class="col-md-10">
			<h1>Detalhar questionário profissiográficoo</h1>
		</div>
		<div *ngIf="origemPesquisarQuestionario" class="col-md-2 mt-2 text-right">
			<sgp-botao-acao-profissiografico
				[listaAcoes]="questionario.acoesPossiveis!"
				[idCargo]="questionario.idCargo"
				exibicao="detalhamento"
			></sgp-botao-acao-profissiografico>
		</div>
	</div>
	<div class="row">
		<div class="col text-right mt-2">
			<button (click)="voltar()">Voltar</button>
		</div>
	</div>
	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-10">
				<span class="text-up-02">Dados do cargo</span>
			</div>
		</div>
		<div class="card-content">
			<div class="row">
				<div class="col-md-3 mt-2 text-semi-bold">Cargo:</div>
				<div class="col-md-9 mt-2">{{ questionario.nomeCargo }}</div>
			</div>
			<div class="row">
				<div class="col-md-3 mt-2 text-semi-bold">Órgão:</div>
				<div class="col-md-9 mt-2">{{ questionario.nomeOrgao }}</div>
			</div>
			<div class="row">
				<div class="col-md-3 mt-2 text-semi-bold">Concurso:</div>
				<div class="col-md-9 mt-2">{{ questionario.nomeConcurso }}</div>
			</div>
			<div class="row">
				<div class="col-md-3 mt-2 text-semi-bold">Período de liberação do questionário:</div>
				<div *ngIf="existeDataLiberacao(questionario.dataInicioLiberacao!); else semData" class="col-md-9 mt-2">
					{{ questionario.dataInicioLiberacao }} a {{ questionario.dataFimLiberacao }}
				</div>
				<ng-template #semData>
					<div class="col-md-9 mt-2">--</div>
				</ng-template>
			</div>
			<div class="row">
				<div class="col-md-3 mt-2 text-semi-bold">Situação:</div>
				<div class="col-md-9 mt-2">{{ questionario.situacao }}</div>
			</div>
		</div>
	</div>
	<p-tabView>
		<p-tabPanel header="Questionário do cargo">
			<sgp-detalhar-questionario-profissiografico
				[questionarioCargo]="questionarioCargo$"
			></sgp-detalhar-questionario-profissiografico>
		</p-tabPanel>
		<p-tabPanel header="Atividades do cargo">
			<sgp-detalhar-atividades-cargo [atividadesCargo]="atividadesCargo$"></sgp-detalhar-atividades-cargo>
		</p-tabPanel>
	</p-tabView>
</div>
<div class="br-divider mt-4"></div>
<div class="row">
	<div class="col text-right mt-2">
		<button (click)="voltar()">Voltar</button>
	</div>
</div>
