import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from '@app/app-routing.module';
import { BotaoAcaoProfissiograficoComponent } from '@app/shared/botao-acao-profissiografico/botao-acao-profissiografico.component';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { DropdownModule } from 'primeng/dropdown';
import { DsGovDirectivesModule } from '@sigepe/dsgov-theme';
import { FiltroPesquisarLaudoComponent } from './filtro-pesquisar-laudo/filtro-pesquisar-laudo.component';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MascaraCpfPipe } from '@app/shared/pipes/mascara-cpf.pipe';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { PesquisarLaudoComponent } from './pesquisar-laudo/pesquisar-laudo.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ResultadoPesquisarLaudoComponent } from './resultado-pesquisar-laudo/resultado-pesquisar-laudo.component';
import { SharedModule } from './../shared/shared.module';
import { SigepeSegurancaModule } from '@sigepe/ngx-sigepe-seguranca';
import { TableModule } from 'primeng/table';

@NgModule({
	declarations: [PesquisarLaudoComponent, FiltroPesquisarLaudoComponent, ResultadoPesquisarLaudoComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		AppRoutingModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		DropdownModule,
		InputTextModule,
		InputMaskModule,
		MessagesModule,
		TableModule,
		BotaoAcaoProfissiograficoComponent,
		SharedModule,
		SigepeSegurancaModule,
		MascaraCpfPipe,
		CoreModule,
		DsGovDirectivesModule
	],
	providers: [MessageService],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class PesquisarLaudoModule {}
