<!-- Formulário (ver exemplo em filtro-pesquisar-laudo.component.html) -->
<div class="row">
	<div class="col-sm-12">
		<form [formGroup]="form">
			<div class="br-card bg-gray-2">
				<div class="card-content">
					<div class="row">
						<div class="col-sm-6 br-input" [ngClass]="{'danger': form.controls['idConcurso'].errors?.required && submetido}">
							<label for="campo-concurso">Concurso (Obrigatório):</label>
							<p-dropdown
								[options]="(dashboardService.concursos$ | async)!"
								formControlName="idConcurso"
								(onChange)="selecionarConcurso($event)"
								placeholder="Selecione"
								[showClear]="true"
								optionLabel="nome"
								optionValue="id"
								id="campo-concurso"
								data-cy="campo-concurso"
								[autoDisplayFirst]="true"
							></p-dropdown>
							<sgp-message-feedback
								[show]="form.controls['idConcurso'].errors?.required && submetido"
								mensagem="Campo obrigatório"
								data-cy="erro-concurso-obrigatorio"
							></sgp-message-feedback>
						</div>
						<!-- </div>
					<div class="row"> -->
						<div class="col-sm-6 br-input">
							<label for="campo-orgao">Órgão:</label>
							<div *possuiPerfil="[perfisEnum.PESQUISADOR, perfisEnum.GESTOR_PRODUTO]">
								<p-dropdown
									[options]="(dashboardService.orgaos$ | async)!"
									formControlName="codigoOrgao"
									placeholder="Selecione um concurso para habilitar o campo"
									[showClear]="true"
									[filter]="true"
									optionLabel="nomeOrgao"
									optionValue="codigoOrgao"
									id="campo-orgao"
									data-cy="campo-orgao"
								></p-dropdown>
							</div>
							<div class="mt-2" *ngIf="somenteGestorPessoas">
								{{ (this.orgaoUsuario$ | async)?.nome.toUpperCase() }}
							</div>
						</div>
					</div>
				</div>
				<div class="card-footer">
					<div class="row mt-4">
						<div class="col text-right">
							<button pButton type="button" data-cy="btnClear" class="secondary mr-2" (click)="limpar()">Limpar</button>
							<button pButton type="submit" data-cy="btnSubmit" class="primary" (click)="pesquisar()">Pesquisar</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
