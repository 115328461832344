<div *ngIf="(atividadesCargo | async) as atvCargo">
	<div class="br-card mt-4">
		<div class="card-header row">
			<div class="col-md-12">
				<span class="text-up-02">Atividades do cargo </span>
			</div>
		</div>
		<div class="card-content">
			<div class="row">
				<div class="col">
					<div class="br-list mt-5" role="list" *ngFor="let atividade of atvCargo.atividades">
						<div class="title text-base text-semi-bold px-3 py-2">
							<div class="row">
								<div class="col-10 text-uppercase">{{atividade.nomeAtividade}}</div>
							</div>
						</div>
						<span class="br-divider"></span>
						<div class="br-list" role="list" *ngFor="let eixoAtividade of atividade.eixosAtividade">
							<div class="title text-base text-semi-bold px-3 py-2">
								<div class="row">
									<div class="col-md-6 pt-2x">{{eixoAtividade.nomeEixo}}</div>
								</div>
							</div>
							<div class="br-item" role="listitem" *ngFor="let fator of eixoAtividade.fatores">
								<div class="row">
									<div class="col-10">{{ fator.nomeFatorAvaliacao }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
