export class GravarAlterarLaudo {
	id?: number;
	emitirLaudo?: boolean;
	conclusaoLaudo?: string;
	comentariosAnaliseEixo?: AnaliseEixo[];
}

export class AnaliseEixo {
	id?: number;
	texto?: string;
}
