import { Component } from '@angular/core';
import { DetalharLaudoService } from './../services/detalhar-laudo.service';

@Component({
	selector: 'sgp-detalhar-cargo',
	templateUrl: './detalhar-cargo.component.html'
})
export class DetalharCargoComponent {
	constructor(protected detalharLaudoService: DetalharLaudoService) {}
}
